import { ReactNode } from "react"
import { MediaText1 } from "schemas"
import { Grid, styled } from "styled-system/jsx"

type Props = {
  media: ReactNode
  content: ReactNode
  position: MediaText1["position"]
}

function Columns({ media, content, position }: Props) {
  if (!media || !content) {
    throw new Error("Missing properties")
  }

  return (
    <Grid gap="40px" columns={{ base: 1, lg: 2 }} justifyItems="center">
      <styled.div
        css={{
          position: "relative",
          order: "1",

          lgDown: {
            maxW: "570px",
          },

          lg: {
            order: position === "left" ? "-1" : "1",
          },
        }}>
        {media}
      </styled.div>
      <styled.div
        css={{
          lgDown: {
            maxW: "570px",
          },
        }}>
        {content}
      </styled.div>
    </Grid>
  )
}

export default Columns
