import Columns from "components/shared/mediaText/MediaTextColumns"
import { Subtitle, Title, TitleWrap } from "components/shared/mediaText/shared"
import { Button } from "components/shared/panda/Button"
import { RoundedTitle } from "components/shared/panda/RoundedTitle"
import PortableTitle from "components/shared/PortableTitle"
import { getHref, parseBackground, useSizing } from "lib/helpers"
import { urlForImage } from "lib/sanity.image"
import { MediaText2 } from "schemas"
import { Container, styled, VStack } from "styled-system/jsx"

type Props = MediaText2

function MediaText2({
  subtitle,
  title,
  contentSubtitle,
  contentTitle,
  body,
  cta,
  image,
  imageHighlight,
  hideMediaMobile,
  position,

  sizingTop,
  sizingBottom,
  backgroundColor1,
  backgroundColor2,
  backgroundStyle,
}: Props) {
  return (
    <styled.div
      style={
        {
          background: parseBackground(
            backgroundColor1,
            backgroundColor2,
            backgroundStyle
          ),
          "--pt": useSizing(sizingTop),
          "--pb": useSizing(sizingBottom),
        } as React.CSSProperties
      }
      css={{
        py: "64px",
        pt: "var(--pt)",
        pb: "var(--pb)",
        bg: "linear-gradient(180deg, rgba(220, 226, 255, 0.19) 0%, rgba(24, 0, 217, 0.19) 100%)",
        color: "other.1",
        overflow: "hidden",
      }}>
      <Container>
        {(title || subtitle) && (
          <TitleWrap>
            {title && <Title mb="8px">{title}</Title>}
            {subtitle && <Subtitle maxW="740px">{subtitle}</Subtitle>}
          </TitleWrap>
        )}

        <Columns
          position={position}
          media={
            <>
              {image?.asset && (
                <styled.img
                  hideBelow={hideMediaMobile && "md"}
                  alt={image.alt || ""}
                  src={urlForImage(image).url()}
                  css={{
                    position: "relative",
                    zIndex: 1,
                  }}
                />
              )}
              {image?.asset && imageHighlight && (
                <styled.img
                  hideBelow={hideMediaMobile && "md"}
                  src="/textures/media-hl-bg.svg"
                  css={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    minW: "450px",
                    width: "250%",
                    maxW: "none",
                    transform: "translate(-50%, -50%)",
                    pointerEvents: "none",

                    lg: {
                      width: "220%",
                    },
                  }}
                />
              )}
            </>
          }
          content={
            <VStack gap={0} alignItems="start">
              {contentSubtitle && (
                <RoundedTitle
                  mb={8}
                  color="primary.4"
                  borderColor="currentcolor"
                  responsive>
                  {contentSubtitle}
                </RoundedTitle>
              )}
              <PortableTitle
                as="div"
                css={{
                  fontFamily: "heading",
                  fontSize: "28px",
                  fontWeight: 700,
                  lineHeight: "126%",
                  mb: 4,

                  lg: {
                    fontSize: "46px",
                  },
                }}
                altColor="primary.4">
                {contentTitle}
              </PortableTitle>
              <styled.p
                css={{
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "133%",
                  opacity: 0.6,

                  lg: {
                    fontSize: "22px",
                  },
                }}>
                {body}
              </styled.p>

              {cta?.url && (
                <Button
                  href={getHref(cta)}
                  variant="outline"
                  outlineColor="secondary"
                  responsiveAlt
                  css={{
                    mt: "32px",
                    color: "primary.4",

                    lg: {
                      mt: "60px",
                    },
                  }}>
                  {cta.text}
                </Button>
              )}
            </VStack>
          }
        />
      </Container>
    </styled.div>
  )
}

export default MediaText2
export type { Props as MediaText2Props }
