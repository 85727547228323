import { styled } from "styled-system/jsx"

export const Title = styled("h3", {
  base: {
    textStyle: "mediaText.title",
    color: "primary.1",
  },

  variants: {
    large: {
      true: {
        lg: {
          fontSize: "60px",
        },
      },
    },
  },
})

export const Subtitle = styled("h4", {
  base: {
    textStyle: "mediaText.subtitle",
    color: "primary.4",
  },
})

export const TitleWrap = styled("div", {
  base: {
    display: "grid",
    justifyItems: "center",
    textAlign: "center",
    mb: "40px",

    lg: {
      mb: "96px",
    },
  },
})
